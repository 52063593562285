import { createStore } from 'vuex'
export default createStore({
  state: {
    // 存放状态
    supplierInfo:{}
  },
  getters: {
    // state的计算属性
    getSupplier(){
      return state.supplierInfo;
    }
  },
  mutations: {
    // 更改state中状态的逻辑，同步操作
    setSupplier(state,info){
      state.supplierInfo=info;
    }

  },
  actions: {
    // 提交mutation，异步操作
    setSupplier(context,info){
      context.commit('setSupplier',info)
    }
  },
  // 如果将store分成一个个的模块的话，则需要用到modules。
   //然后在每一个module中写state, getters, mutations, actions等。
  modules: {
    // ...
  }
});
