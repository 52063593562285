<template>
  <router-view></router-view>
</template>
<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  min-width: 1400px;
}
</style>
